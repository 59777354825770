<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <p style="font-weight: bold;">Καλώς ήρθατε στο ανοιχτό για όλες και όλους ΨΗΦΙΑΚΟ ΑΠΟΘΕΤΗΡΙΟ της Διεύθυνσης Υγείας &amp; Ασφάλειας στην Εργασία
του Υπουργείου Εργασίας και Κοινωνικής Ασφάλισης
για την τεκμηρίωση, αξιοποίηση και προβολή

της ψηφιακής πληροφορίας, του ψηφιακού υλικού και των ψηφιακών υπηρεσιών

στον τομέα της Υγείας &amp; Ασφάλειας στην Εργασία (Υ.Α.Ε.)</p>
          <p>
Το ΨΗΦΙΑΚΟ ΑΠΟΘΕΤΗΡΙΟ αποτελεί μια νέα ψηφιακή υπηρεσία

της Διεύθυνσης Υγείας &amp; Ασφάλειας στην Εργασία του Υπουργείου Εργασίας και Κοινωνικής Ασφάλισης (Υ.Ε.Κ.Α.) και  στοχεύει στην ευρύτερη και καλύτερη αξιοποίηση του διαθέσιμου ψηφιακού περιεχομένου
που παράγεται στο πλαίσιο των αρμοδιοτήτων που ασκεί και του έργου που υλοποιεί η Διεύθυνση Υ.Α.Ε. ως επιτελική υπηρεσία του Υ.Ε.Κ.Α. στον τομέα της Υγείας και Ασφάλειας στην Εργασία.
Καλύπτει όλα τα θέματα που αφορούν στην Υγεία &amp; Ασφάλεια στην Εργασία,περιλαμβάνει νομοθετικά κείμενα, εγκυκλίους, άρθρα, κατευθυντήριες οδηγίες, ενημερωτικά φυλλάδια, αφίσες,
παρουσιάσεις, βίντεο, φωτογραφικό υλικό και άλλο υλικό προβολής, καθώς και όλο το παραγόμενο υλικό από την συμμετοχή της Διεύθυνσης Υ.Α.Ε. σε ευρωπαϊκά και εθνικά προγράμματα και αποτελεί πνευματική ιδιοκτησία της.
</p>
<p>Το ΨΗΦΙΑΚΟ ΑΠΟΘΕΤΗΡΙΟ, σχεδιάστηκε και αναπτύχθηκε στο πλαίσιο του έργου «Ολοκληρωμένο Πληροφοριακό Σύστημα της Γενικής Γραμματείας Εργασιακών Σχέσεων για την Ασφάλεια και την Υγεία στην Εργασία (Ο.Π.Σ. ΗΡΙΔΑΝΟΣ)»
Εθνικό Σχέδιο Ανάκαμψης και Ανθεκτικότητας – «Ελλάδα 2.0».           

 </p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <small class="credits">Photo by <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a></small>
</div>

